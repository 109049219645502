import { Link } from "gatsby"
import React from "react"
import logo from "../assets/icons/logo.svg"
import { graphql, useStaticQuery } from "gatsby"
import Button from "./button"
import FeaturesCardMd from "./featureCardMd"
import IconContainer from "./iconContainer"
import Icon from "./icon"

const Navbar = (props) => {
	const url = typeof window !== 'undefined' ? window.location.href : '';
	console.log(url)
	const data = useStaticQuery(graphql`
		query {
			allContentfulFeature {
				edges {
					node {
						title
						slug
						icon
						featured
						summary {
							summary
						}
					}
				}
			}
		}
	`)
	
	if (typeof window !== `undefined`) {
		window.addEventListener('scroll', function() {
			if (window.scrollY > 200) {
				document.querySelectorAll('nav').forEach(navElement => {
					navElement.classList.add('bg-dark-blue');
				})
			}
			else {
				document.querySelectorAll('nav').forEach(navElement => {
					navElement.classList.remove('bg-dark-blue');
				})
			}
		})
	}
    const openNav = () => {
      document.querySelector(`.nav-links-mobile`).style.width = "90vw";
      document.querySelector(`.nav-links-mobile`).style.height = "90vw";
      document.querySelector(`.open-btn`).style.display = "none";
      document.querySelector(`.close-btn`).style.display = "block";
      document.querySelector(`.nav-links-mobile`).style.opacity = "1";
    }
    const closeNav = () => {
      document.querySelector(`.nav-links-mobile`).style.opacity = "0";
      document.querySelector(`.nav-links-mobile`).style.width = "0";
      document.querySelector(`.nav-links-mobile`).style.height = "0";
      document.querySelector(`.close-btn`).style.display = "none";
      document.querySelector(`.open-btn`).style.display = "block";
    }

    return (
        <div style={{position: props.style.position || 'sticky', top: '38px', zIndex:'10000000'}}>
        <nav className={`nav ${props.style.classes}`}>
          <div className={`container-fluid nav-container`}>
              <div className="nav__left">
                  <Link to="/" className={"title"}>
                      <img src={logo} className="logo mr-5" alt="logo"/>
                  </Link>
              <div className={"nav-links"}>
                  {/* <Link to="/" className={"link"} activeClassName={"link-active"} >Home</Link> */}
				<div className="dropdown nav-item d-flex align-items-center">
                    <span className="link link__resources" data-t4oggle="dropdown">Use cases</span>
                    <div className="dropdown-menu fade-up" style={{width: '210px'}}>
                      <Link to="/launch-your-new-marketplace-with-solid-foundations" className={"link__dropdown ml-4"} >
                        <span>Launch a New Marketplace</span>
                      </Link>
                      <Link to="/expand-product-catalog-with-zero-inventory-risk" className={"link__dropdown ml-4"} >
                        <span>Expand Product Catalog</span>
						</Link>
                      <Link to="/scale-your-marketplace-efficiently-to-future-proof-your-business" className={"link__dropdown ml-4"} >
                        <span>Scale Your Marketplace</span>
                      </Link>

                    </div>
                  </div> 
					<div className="dropdown nav-item d-flex align-items-center">
						<Link to={"/features"} className={"link"} >	
							<span className="link link__resources mx-0" data-toggle="dropdown">Features</span>
						</Link>
						<div className='dropdown-menu center-fix fade-up'>
							<div className='mx-3' style={{background: 'white', width: '800px', borderRadius: '6px'}}>
								<div className='row justify-content-around'>
									{data.allContentfulFeature.edges.map((edge) => {
											if (edge.node.featured) {
												return(
													<Link to={`/features/${edge.node.slug}`} className='feature-card-sm'>
														<div className='col-3 d-flex my-3 align-items-center feat-card-sm'>
															<img className='w-25' src={require(`../assets/icons/features/${edge.node.slug}.svg`)} />
															<p className='ml-3'>{edge.node.title}</p>
														</div>
													</Link>
												)
											}
										}
										)
									}
									<Link to={'/features'} className='feature-card-sm'>
										<div className='col-3 d-flex my-3 align-items-center feat-card-sm reverse'>
											<img className='w-25' src={require(`../assets/icons/features/all.svg`)} />
											<p className='ml-3'>All features</p>
										</div>
									</Link>
								</div>
								{/* <FeaturesCardMd content={{ slug: "/", title: "Discover all the features", icon: "all"}} style={{ color: "feature-card-md__reverse mx-auto my-4"}}/> */}
							</div>
						</div>
					</div>
                  <Link to="/composable" className={"link"} activeClassName={"link-active"} >Composable</Link>
                  <Link to="/enterprise" className={"link"} activeClassName={"link-active"} >Enterprise</Link>
					<div className="dropdown nav-item d-flex align-items-center">
						<Link to={"/partners"} className={"link"} >	
							<span className="link link__resources mx-0" data-toggle="dropdown">Partners</span>
						</Link>
						<div className='dropdown-menu fade-up' style={{background: 'rgba(0,0,0,0)',height: '320px'}}>
							<div className= 'd-flex' style={{background: 'white',height: '320px', width: '800px', borderRadius: '6px'}}>
								<div className='w-100 row py-2 pl-4'>
									<div className='col-3'>
										<Link to={"/partners/store#filter"} className={"category-link"} >
											<p className="feature-highlight__title" style={{lineHeight: '1.6'}}>Store</p>
										</Link>
										<p className="feature-highlight__description" style={{lineHeight: '1.6'}}>Connect Onport to a supported Storefront</p>
										<div className='d-flex mt-3'>
											<Link to={"/partners/store/elastic-path"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/elastic-path.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/store/shopify"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/shopify.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/store/bigcommerce"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/bigcommerce.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
										</div>
									</div>
									<div className='col-3'>
										<Link to={"/partners/dropship#filter"} className={"category-link"} >
											<p className="feature-highlight__title" style={{lineHeight: '1.6'}}>Dropship</p>
										</Link>
										<p className="feature-highlight__description" style={{lineHeight: '1.6'}}>Integrations for your dropshipping operations</p>
										<div className='d-flex mt-3'>
											<Link to={"/partners/dropship/dropship-shopify"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/shopify.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/dropship/google-drive"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/google-drive.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/dropship/dropship-big-commerce"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/bigcommerce.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
										</div>
									</div>
									<div className='col-3'>
										<Link to={"/partners/shipping#filter"} className={"category-link"} >
											<p className="feature-highlight__title" style={{lineHeight: '1.6'}}>Shipping</p>
										</Link>
										<p className="feature-highlight__description" style={{lineHeight: '1.6'}}>Shipping integrations for your marketplace</p>
										<div className='d-flex mt-3'>
											<Link to={"/partners/shipping/shippo"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/shippo.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/shipping/shippypro"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/shippypro.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/shipping/usps"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/usps.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
										</div>
									</div>
									<div className='col-3'>
										<Link to={"/partners/payments#filter"} className={"category-link"} >
											<p className="feature-highlight__title" style={{lineHeight: '1.6'}}>Payments</p>
										</Link>
										<p className="feature-highlight__description" style={{lineHeight: '1.6'}}>Streamline your payment processes with ease</p>
										<div className='d-flex mt-3'>
											<Link to={"/partners/payments/paypal"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/paypal.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/payments/stripe"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/stripe.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/payments/wise"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/transferwise.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
										</div>
									</div>
									<div className='col-3'>
										<Link to={"/partners/accounting#filter"} className={"category-link"} >
											<p className="feature-highlight__title" style={{lineHeight: '1.6'}}>Accounting</p>
										</Link>
										<p className="feature-highlight__description" style={{lineHeight: '1.6'}}>Easily keep on top of your financial obligations</p>
										<div className='d-flex mt-3'>
											<Link to={"/partners/accounting/quickbooks"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/quickbooks.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/accounting/xero"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/xero.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/accounting/taxjar"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/taxjar.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
										</div>
									</div>
									<div className='col-3'>
										<Link to={"/partners/automation#filter"} className={"category-link"} >
											<p className="feature-highlight__title" style={{lineHeight: '1.6'}}>Automation</p>
										</Link>
										<p className="feature-highlight__description" style={{lineHeight: '1.6'}}>Keep your marketplace running smoothly</p>
										<div className='d-flex mt-3'>
											<Link to={"/partners/automation/intercom"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/intercom.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/automation/slack"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/slack.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/automation/mailgun"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/mailgun.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
										</div>
									</div>
									<div className='col-3'>
										<Link to={"/partners/ecosystem#filter"} className={"category-link"} >
											<p className="feature-highlight__title" style={{lineHeight: '1.6'}}>Ecosystem</p>
										</Link>
										<p className="feature-highlight__description" style={{lineHeight: '1.6'}}>Ecosystem partners for your marketplace</p>
										<div className='d-flex mt-3'>
											<Link to={"/partners/ecosystem/carriboo"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/carriboo.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/ecosystem/shopbox-ai"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/shopbox-ai.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
										</div>
									</div>
									<div className='col-3'>
										<Link to={"/partners#filter"} className={"category-link"} >
											<p className="feature-highlight__title" style={{lineHeight: '1.6'}}>All Partners</p>
										</Link>
										<p className="feature-highlight__description" style={{lineHeight: '1.6'}}>Explore all our partners</p>
										{/* <div className='d-flex mt-3'>
											<Link to={"/partners/automation/intercom"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/intercom.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/automation/slack"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/slack.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
											<Link to={"/partners/automation/mailgun"} className={"integration-link"} >
												<img src={require(`../assets/icons/platform/round/mailgun.svg`)} style={{ height: '32px', marginRight: '4px'}}/>
											</Link>
										</div> */}
									</div>
								</div>

							</div>
						</div>
					</div>
					<Link to="/casestudies" className={"link"} activeClassName={"link-active"} >Case Studies</Link>
                  {/* <div className="dropdown nav-item d-flex align-items-center">
                    <span className="link link__resources" data-t4oggle="dropdown">Resources</span>
                    <div className="dropdown-menu fade-up" style={{width: '210px'}}>
                      <Link to="/casestudies" className={"link__dropdown ml-4"} >
                        <img src='https://taact-brand-assets.netlify.app/assets/icons/jetti/policy.svg' alt="nav-icon" className="link__icon"/>
                        <span>Case studies</span>
                      </Link>
                        <a href="https://support.jetti.io/jetti/en" className={"link__dropdown ml-4"} target="_blank" rel="noreferrer">
                      <img src='https://taact-brand-assets.netlify.app/assets/icons/jetti/help.svg' alt="nav-icon" className="link__icon"/>
                        <span>Support</span>
                      </a>
                      <Link to="/support-packages" className={"link__dropdown ml-4"} >
                        <img src='https://taact-brand-assets.netlify.app/assets/icons/jetti/enterprise.svg' alt="nav-icon" className="link__icon"/>
                        <span>Support Packages</span>
                      </Link>
                      <Link to="/about" className={"link__dropdown ml-4"} >
                        <img src='https://taact-brand-assets.netlify.app/assets/icons/jetti/home.svg' alt="nav-icon" className="link__icon"/>
                        <span>About</span>
                      </Link>
                    </div>
                  </div> */}
				  <Link to="/blog" className={"link"} activeClassName={"link-active"} >Blog</Link>
				  <Link to="/contacts/sellers" className={"link"} activeClassName={"link-active"} >Sellers</Link>
              </div>
			  </div>

              <div className="nav__right">
				{url != 'http://onport.com/' && <Link className="btnOn dark-bg-alt mr-3"  to="/contacts/book-demo">Book Demo</Link>}
                <Button style={{ shape: "text-light", text: "Sign in", href: "https://app.jetti.io/login"}}/>
              </div>
            </div>
        </nav>
		
        <nav className={`nav-mobile position-absolute ${props.style.classes}`}>
          <div className={`container-fluid nav-container`}>
              <div className="">
                  <Link to="/" className={"title"}>
                      <img src={logo} className="logo" alt="logo"/>
                  </Link>
              </div>
              <span className={"open-btn"} onClick={openNav}>&equiv;</span>
              <div className={"nav-links-mobile"}>
                <div className="nav-links-mobile__links">
                  <div className="nav-links-mobile__col">
                    <span className={"close-btn"} onClick={closeNav}>&times;</span>
                    <Link to="/" className={"link"} activeClassName={"link-active"}>Home</Link>
                    <Link to="/partners" className={"link"} activeClassName={"link-active"}>Integrations</Link>
                    <Link to="/features" className={"link"} activeClassName={"link-active"}>Features</Link>
                    <Link to="/enterprise" className={"link"} activeClassName={"link-active"} >Enterprise</Link>
                    <Link to="/contacts/sellers" className={"link"} activeClassName={"link-active"} >Sellers</Link>
                  </div>
                  <div className="nav-links-mobile__col">
                    <Link to="/casestudies" className={"link"} activeClassName={"link-active"}>Success Stories</Link>
                    <Link to="/blog" className={"link"} activeClassName={"link-active"}>Blog</Link>
                    <a to="https://www.onport.com/contacts/book-demo" className={"link"} target="_blank" rel="noreferrer">Support</a>
                    <Link to="/about" className={"link"} activeClassName={"link-active"}>About</Link>
                  </div>
                </div>
                <div className="mx-auto my-3">
                </div>
              </div>
            </div>
        </nav>
      </div>
    )
}


export default Navbar
