import { Link } from "gatsby"
import React from "react"
import logo from "../../../assets/icons/logo.svg"
import Button from "../../button"
import Paragraph from "../paragraph/paragraph"
import Title from "../title/title"
import Container from "../container/container"
import Grid from "../grid/grid"
import Flex from "../flex/flex"
import Block from "../block/block"
import './footer.scss'


const Footer = (props) => {
    return (
        <Container style='fluid brandDarker py-5 footerOn'>
            <Container style=''>
                <Block style='row'>
                    <Block style='col-12 col-md-4'>
                        <img src={logo} className="logo mt-3" alt="logo"/>
                        <Paragraph text='Navigate your business with Onport, a multi-vendor marketplace solution to scale your operations.' style='text-light mt-3'/>
                    </Block>
                    <Block style='col-12 col-md-8'>
                        <Block style='row w-100 justify-content-between'>
                            <Block style='col-12 col-md-3'>
                                <Title title='Referrals' style='text-light'/>
                                <Link className='text-light' to="https://referral-program.onport.com/?utm_campaign=Onport_referral_program&utm_source=website">Referral Program</Link>
                            </Block>
                            <Block style='col-12 col-md-3'>
                                <Title title='Product' style='text-light'/>
                                <Link className='text-light' to="/partners">Integrations</Link>
                                <Link className='text-light' to="/features">Features</Link>
                                <Link className='text-light' to="/composable">Composable</Link>
                                <Link className='text-light' to="https://composable.jetti.io/">Dev Docs</Link>
                                <Link className='text-light' to="/support-packages">Support Packages</Link>
                            </Block>
                            <Block style='col-12 col-md-3'>
                                <Title title='Resources' style='text-light'/>
                                <Link to="/about">About</Link>
                                <Link to="https://onport.factorialhr.com/">Careers</Link>
                                <Link to="/casestudies">Case Studies</Link>
                                <Link to="/blog">Blog</Link>
                            </Block>
                            <Block style='col-12 col-md-3'>
                                <Title title='Help' style='text-light'/>
                                <Link to="/terms">Terms</Link>
                                <Link to="/privacy">Privacy</Link>
                            </Block>
                        </Block>
                    </Block>
                </Block>
                <Block style='row mt-5'>
                    <Block style='col-12 col-md-6'>
                        <Paragraph text='©2024 Onport. All rights reserved' style='text-light'/>
                    </Block> 
                    <Block style='col-12 col-md-6 text-right'>
                        <a className='social' href="https://www.youtube.com/channel/UCpHTLMOOXt-mhrkxNyWAPiw"><img src={require("../../../assets/icons/youtube.svg")} alt="jetti-youtube" /></a>
                        <a className='social' href="https://twitter.com/onport_hq"><img src={require("../../../assets/icons/twitter.svg")} alt="jetti-twitter" /></a>
                        <a className='social'  href="https://www.linkedin.com/company/onport/"><img src={require("../../../assets/icons/linkedin.svg")} alt="jetti-linkedin" /></a>
                    </Block> 
                </Block>
            </Container>
        </Container>
    )
}

export default Footer